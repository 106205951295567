@media all and (max-width: 400px) {
  #playBtn {
    width: 20px;
  }
  #slideButton {
    font-size: 0.9rem;
    padding: 0 16px;
    height: 10px;
    line-height: 1.5;
  }
}
