// @font-face {
//   font-family: "Filson Pro Regular";
//   src:url('../../fonts/pro-regular.otf');
// }

// @font-face {
//   font-family: 'Filson Pro Black-Italic';
//   src:url('../../fonts/Filson-Pro-Black-Italic-typeface.otf');
// }

// @font-face {
//   font-family: 'Filson Pro Light';
//   src:url('../../fonts/Filson-Pro-Light-Typeface.otf');
// }

// @font-face {
//   font-family: FilsonProBook;
//   src:url('../../fonts/Filson-Pro-Book-Typeface.otf');
// }

// @font-face {
//   font-family: FilsonProMedium;
//   src:url('../../fonts/Filson-Pro-Medium-Typeface.otf');
// }

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: normal !important;
}
body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: "Montserrat", Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}
blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.6em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
  margin-top: 10px;
  margin-bottom: 10px;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 500;
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

p {
  margin: 5px;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
  font-size: 18px;
}

blockquote p {
  font-style: italic;
}

a {
  color: #6cca98;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #5e7310c7;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

MuiButton-root {
  font-family: "Montserrat", "Roboto", "Helvetica", "Arial", sans-serif;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  // color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}
svg {
  touch-action: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-weight: 400;
  line-height: 1;
  color: #777;
}

h4 small,
h5 small,
h6 small {
  font-size: 75%;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}
img {
  vertical-align: middle;
  border: 0;
}
.outerDiv {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
}

.controlsDiv {
  height: 100vh;
  background-color: lightgray;
}

.innerDivIos {
  height: 80vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 0.9vh) * 90);
  margin: 0 auto;
  // height: 95vh;
}

// .innerDivMobile {
//   height: 90vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
//   height: calc(var(--vh, 1vh) * 90);
//   margin: 0 auto;
// }

.innerDiv {
  margin: 0 auto;
  height: 90vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 90);
}

body,
html {
  overflow: hidden;
}

.needle {
  display: none;
}
.rcw-launcher {
  background-color: #fbd16e;
}

.rcw-conversation-container > .rcw-header {
  background-color: #6cca98;
}

.rcw-widget-container {
  font-size: 20px;
}
.rcw-conversation-container > .rcw-header > .rcw-close-button {
  background-color: #6cca98;
}

.rcw-message > .rcw-response {
  background-color: #2a7050;
  color: white;
}
.rcw-message > .rcw-client {
  background-color: #6cca98;
  color: white;
}

.rcw-new-message {
  font-size: 20px;
}
